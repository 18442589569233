import VueSidebarMenu from "vue-sidebar-menu";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue'
import App from './App'
import auth from './infraestructure/plugins/auth.js'
import router from './router';
import Vuelidate from 'vuelidate'


import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue';
import dataModule from "highcharts/modules/data";
import exportingInit from "highcharts/modules/exporting";
import exportingCsv from "highcharts/modules/export-data";
import loadStock from 'highcharts/modules/stock.js';
import HighchartsNoData from 'highcharts/modules/no-data-to-display'


import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";

//config no data chart
HighchartsNoData(Highcharts)

//config export data
dataModule(Highcharts);
exportingInit(Highcharts)
exportingCsv(Highcharts)

loadStock(Highcharts)

//set global variable for keycloak
Vue.use(auth)

//lib to validate forms
Vue.use(Vuelidate)

//set bootstrap vue as global
Vue.use(BootstrapVue);
Vue.use(VueSidebarMenu)

//vue charts
Vue.use(HighchartsVue);

//font awesome
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

const defaultRoute = window.location.protocol + '//' + window.location.host + '/#/';

//initialize keycloak on app
Vue.$keycloak.init({ pkceMethod: 'S256' }).then((auth) => {
    if (!auth) {
        Vue.$keycloak.login({ redirectUri: defaultRoute });
    } else {
        //render application
        new Vue({
            router,
            render: h => h(App),
        }).$mount('#app')
    }


    //Token Refresh
    setInterval(() => {
        Vue.$keycloak.updateToken(60).then((refreshed) => {
            if (refreshed) {
                console.log('token refreshed...')
            }
        }).catch(() => {
            console.log('token NOT refreshed...')
        });
    }, 60000)

});