import Vue from 'vue'
import Keycloak from 'keycloak-js'
import config from '../keycloak/config.js'

const _keycloak = Keycloak(config)

const Plugin = {
    install(Vue) {
        Vue.$keycloak = _keycloak
    }
}

Plugin.install = Vue => {
    Vue.$keycloak = _keycloak
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak
            }
        }
    })
}

Vue.use(Plugin)

export default Plugin