<template>
  <div>
    <div id="view" :class="[{ collapsed: collapsed }]">
      <router-view />
    </div>
    <sidebar-menu
      class="sidebar"
      :menu="menus"
      :collapsed="collapsed"
      @collapse="onCollapse"
    />
  </div>
</template>

<script>
import Vue from "vue";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      menus: [
        {
          header: true,
          title: "License Inventory",
        },
      ],
      collapsed: true,
    };
  },
  methods: {
    /**
     * Sets the collapsed state based on the provided value.
     * @param {boolean} c - The value indicating whether the component should be collapsed.
     */
    onCollapse(c) {
      this.collapsed = c;
    },
  },
  /**
   * Executes logic to populate menus based on user roles when the component is created.
   */
  created() {
    // Decode the JWT token
    const parsedToken = jwt_decode(Vue.$keycloak.token);
    // Extract menus from the parsed token
    const menus = parsedToken.role_attributes.menus[0].split(",");

    // Populate menus based on user's roles
    if (menus.includes("DASHBOARD"))
      this.menus.push({
        href: "/dashboard",
        title: "Dahsboard",
        icon: "fa fa-chart-line",
      });

    if (menus.includes("EMPRESA"))
      this.menus.push({
        href: "/company",
        title: "Empresas",
        icon: "fa fa-briefcase",
      });

    if (menus.includes("USUARIO"))
      this.menus.push({ href: "/user", title: "Usuarios", icon: "fa fa-user" });
  },
};
</script>

<style>
#view {
  -webkit-transition: padding-left 0.3s ease-in-out;
  -moz-transition: padding-left 0.3s ease-in-out;
  -o-transition: padding-left 0.3s ease-in-out;
  transition: padding-left 0.3s ease-in-out;
  padding-left: 350px;
}

.alert {
  margin-bottom: 0 !important;
}

.sidebar.v-sidebar-menu .vsm-arrow:after {
  content: "\f105";
  font-family: "FontAwesome";
}

.sidebar.v-sidebar-menu .collapse-btn:after {
  content: "\f07e";
  font-family: "FontAwesome";
}

.v-sidebar-menu .vsm-item.first-item > .vsm-link > .vsm-icon {
  background-color: transparent !important;
}

#view.collapsed {
  -webkit-transition: padding-left 0.1s ease-in-out;
  -moz-transition: padding-left 0.1s ease-in-out;
  -o-transition: padding-left 0.1s ease-in-out;
  transition: padding-left 0.1s ease-in-out;
  padding-left: 50px;
}

.v-sidebar-menu .vsm--mobile-bg {
  background-color: red !important;
}

.v-sidebar-menu {
  background-color: rebeccapurple !important;
}

.header-app {
  background-color: rebeccapurple !important;
}

.collapse-btn {
  background-color: rebeccapurple !important;
}

.v-sidebar-menu .vsm-item.first-item.active-item > .vsm-link,
.v-sidebar-menu .vsm-item.first-item.parent-active-item > .vsm-link {
  box-shadow: 3px 0px 0px 0px orange inset !important;
}

.collapse-container{
  min-height: 100px;
}

.collapse-button {
  border-color: transparent !important;
  background-color: orange !important;
  box-shadow: none !important;
}

.vsm-mobile-bg {
  background-color: orange !important;
}

.tab-content{
  margin-bottom: 3%;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
</style>