import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import('./views/app'),
        name: 'home',
        children: [
            {
                path: '/dashboard',
                component: () => import('./views/app/dashboard/view'),
                name: 'dashboard',
            },
            {
                path: '/company',
                component: () => import('./views/app/company/view'),
                name: 'company',
            },
            {
                path: '/user',
                component: () => import('./views/app/user/view'),
                name: 'user'
            },
        ]
    },
];

const router = new VueRouter({
    routes,
});

export default router;
